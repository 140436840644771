<template>
  <modal
    id="chatmodal"
    :isVisible="isShowPop"
    :title="title"
    :showBtn="true"
    :isShowCancel="true"
    @close-popup="$emit('close')"
    width="790px"
    :submitText="'SEND'"
    :cancelText="'Cancel'"
    @submit="sendMessage"
    :disabled="loading"
  >
    <template #content>
      <a-spin :spinning="loading">
        <div class="item font-bold" style="margin-top: 10px">
          <div style="width: 300px">Choose An Email Template:</div>

          <a-select
            class="select"
            placeholder="Choose a template or not"
            v-model="tpl"
            @change="handleTemplateChange"
            size="large"
          >
            <a-icon slot="suffixIcon" type="caret-down" class="icon-down" />
            <a-select-option value="TEMPLATE_NULL"
              >Create My Own</a-select-option
            >
            <a-select-option value="TEMPLATE_PPC_Violation"
              >Publisher TM+ Bidding</a-select-option
            >
            <a-select-option value="TEMPLATE_PROMOTIONAL_NOT_MATCH"
              >Publisher Promotional Method Does Not Match</a-select-option
            >
          </a-select>
        </div>
        <div class="mail-content">
          <template v-if="mediumId">
            <div class="item">
              <div class="font-bold">Subject</div>
              <a-input v-model="content_title" size="large"></a-input>
            </div>
            <div class="item">
              <div class="font-bold">Content</div>
              <a-textarea
                v-model="content"
                :auto-size="{ minRows: 12, maxRows: 20 }"
              />
            </div>
          </template>
          <template v-else-if="userId">
            <div class="item">
              <div class="font-bold">Subject</div>
              <a-input v-model="content_title" size="large"></a-input>
            </div>
            <div class="item">
              <div class="font-bold">Content</div>
              <a-textarea
                v-model="content"
                :auto-size="{ minRows: 12, maxRows: 20 }"
              />
            </div>
          </template>
        </div>
      </a-spin>
    </template>
  </modal>
</template>
<script>
import Modal from "./Modal.vue";
import axios from "@/utils/request";
import APIS from "@/api/const";

const TEMPLATE_NULL = "";

const TEMPLATE_PPC_Violation = `Hello {firstname} ({username}),

We have found a PPC advertisement of yours that violates our published PPC policy.

The details of the advertisement are below.


Please have this violation removed in 24 hours and confirm with our team.Failure to cease bidding could result in your termination from the the program and forfeiture of unpaid commissions.

To avoid getting terminated from the program and rejection of all commissions earned, please add all possible brand name forms (also using another keyboard layout, with typos and mistakes) to the negatives in your ad campaign.

Regards,
{merchantname} ({merchantid})`;

const TEMPLATE_PROMOTIONAL_NOT_MATCH = `Hello {firstname} ({username}),

We regret to inform you that your promotional method does not meet our requirements. Please stop promoting.

Regards,
{merchantname} ({merchantid})`;

const defaultLogo = require("@/assets/default.png");

export default {
  name: "chat-model",
  components: { Modal },
  props: {
    mediumId: {
      default: null,
      type: String,
    },
    reply: {
      type: Object,
      default: () => null,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  watch: {
    mediumId(newV, oldV) {
      if (!newV) {
        this.medium = null;
        return;
      }
      this.loading = true;
      axios
        .get("/api_admin.php?a=medium/detail", {
          params: { medium_id: newV },
        })
        .then((res) => {
          this.loading = false;
          if (res.code === 0) {
            let flag;
            try {
              flag = require("@/assets/flag/" + res.data.country + ".png");
            } catch (e) {
              flag = undefined;
            }
            this.medium = {
              ...res.data,
              logo: res.data.logo
                ? this.APP_CONFIG.OSS_HOST + res.data.logo
                : defaultLogo,
              flag,
              aff_ba: null,
              applied: null,
            };
            this.tpl = "TEMPLATE_NULL";
            this.handleTemplateChange(this.tpl);
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    isShowPop(newV, oldV) {
      if (!newV) {
        this.tpl = "TEMPLATE_NULL";
        this.content = "";
        this.content_title = "";
      }
    },
  },
  data() {
    return {
      loading: false,
      content: "",
      content_title: "",
      tpl: "TEMPLATE_NULL",
      medium: null,
      templates: {
        TEMPLATE_NULL,
        TEMPLATE_PPC_Violation,
        TEMPLATE_PROMOTIONAL_NOT_MATCH,
      },
      subjects: {
        TEMPLATE_NULL: "",
        TEMPLATE_PPC_Violation:
          "Violation of Our Paid Search Policy - {merchantname} {merchantid}",
        TEMPLATE_PROMOTIONAL_NOT_MATCH: "Please Pause the Campaign(s)!",
      },
    };
  },
  computed: {
    title() {
      return this.mediumId
        ? "Send A Message To The Publisher"
        : this.userId
        ? "Send A Message To The Influencer"
        : "Reply A Message To " + this.reply?.from_id;
    },
    isShowPop() {
      return this.userId != null || this.mediumId != null || this.reply != null;
    },
  },
  methods: {
    handleTemplateChange(val) {
      if (this.mediumId) {
        this.content = this.templates[val]
          .replace("{firstname}", this.medium.first_name)
          .replace("{username}", this.medium.uname)
          .replace(
            "{merchantname}",
            this.$store.state.accountInfo.merchant_info.company
          )
          .replace(
            "{merchantid}",
            this.$store.state.accountInfo.merchant_info.id
          );
        this.content_title = this.subjects[val]
          .replace(
            "{merchantname}",
            this.$store.state.accountInfo.merchant_info.company
          )
          .replace(
            "{merchantid}",
            this.$store.state.accountInfo.merchant_info.id
          );
      } else if (this.userId) {
        this.content = this.templates[val]
          .replace("{firstname}", " ")
          .replace("{username}", " ")
          .replace("{merchantname}", " ")
          .replace("{merchantid}", " ");
        this.content_title = this.subjects[val]
          .replace("{merchantname}", " ")
          .replace("{merchantid}", " ");
      }
    },
    sendMessage() {
      if (this.content == "") {
        this.$message.error("Please input message");
      } else {
        // 执行发消息,且关闭窗口
        this.loading = true;
        if (this.mediumId || this.userId) {
          let type = "site";
          if (this.userId) {
            type = "user";
          }
          axios
            .post(APIS.COMMON.SEND_EMAIL, {
              to_type: type,
              to_id: this.mediumId || this.userId,
              content: this.content,
              title: this.content_title,
              template: this.tpl,
            })
            .then((res) => {
              this.loading = false;
              this.$emit("close");
              if (res.code === 0) {
                this.$message.success("Send Success.");
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          axios
            .post("/api_admin.php?a=message/reply", {
              id: this.reply.id,
              content: this.content,
            })
            .then((res) => {
              this.loading = false;
              this.$emit("close");
              if (res.code === 0) {
                this.$message.success("Send Success.");
              } else {
                this.$message.error(res.msg);
              }
            });
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
#chatmodal {
  /deep/ .btn-submit-close {
    justify-content: flex-end;

    > * {
      margin: 0;

      &:first-child {
        margin-right: 30px;
      }
    }
  }

  .item {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.font-bold {
      padding-bottom: 10px;
      margin-top: 10px;
      font-size: 1.1429rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .mail-content {
    background-color: #f2f9ffff;
    padding: 20px;
    margin-top: 10px;
    border-radius: 6px;

    .item {
      > div:first-child {
        font-size: 1.1429rem;
        width: 90px;
        align-self: flex-start;
        margin-right: 10px;
        line-height: 2.8571rem;
      }
    }
  }

  .select {
    width: 100%;
  }
}
</style>
