<template>
  <div class="menu-wrap">
    <ul class="menu">
      <li>
        <router-link
          :to="{ name: 'dashboard' }"
          :class="isActMenu == 'Dashboard' ? 'active' : ''"
        >
          Dashboard
          <a-icon type="caret-down" style="opacity: 0" />
        </router-link>
      </li>
      <li v-if="hasTransactionList || hasReportCall">
        <a-dropdown placement="bottomLeft">
          <a
            class="ant-dropdown-link"
            @click="(e) => e.preventDefault()"
            :class="isActMenu == 'Reports' ? 'active' : ''"
          >
            Reports
            <a-icon type="caret-down" class="c-down" />
          </a>
          <a-menu slot="overlay" class="menu-dropdown">
            <a-menu-item v-if="hasReportCall">
              <router-link :to="{ name: 'report_reports' }"
                >Performance</router-link
              >
            </a-menu-item>
            <a-menu-item v-if="hasTransactionList">
              <router-link :to="{ name: 'report_orders' }"
                >Transaction</router-link
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </li>
      <li>
        <router-link
          :to="{ name: 'campaign' }"
          :class="isActMenu == 'Campaign' ? 'active' : ''"
        >
          Campaign
          <a-icon type="caret-down" style="opacity: 0" />
        </router-link>
      </li>
      <li v-if="hasMediumList || hasMediumSearch">
        <a-dropdown placement="bottomLeft">
          <a
            class="ant-dropdown-link"
            @click="(e) => e.preventDefault()"
            :class="isActMenu == 'Publisher' ? 'active' : ''"
          >
            Publishers
            <a-icon type="caret-down" class="c-down" />
          </a>
          <a-menu slot="overlay" class="menu-dropdown">
            <a-menu-item v-if="hasMediumList">
              <router-link :to="{ name: 'publisher_list' }"
                >Publisher List</router-link
              >
            </a-menu-item>
            <a-menu-item v-if="hasMediumList">
              <router-link :to="{ name: 'publisher_pending' }"
                >Pending Publishers</router-link
              >
            </a-menu-item>
            <a-menu-item v-if="hasMediumSearch">
              <router-link :to="{ name: 'discovery' }"
                >Publisher Discovery</router-link
              >
            </a-menu-item>
            <a-menu-item v-if="hasTransactionList">
              <router-link :to="{ name: 'transaction_inquiry' }"
                >Transaction Inquiries</router-link
              >
            </a-menu-item>
            <a-menu-item v-if="hasTransactionList">
              <router-link :to="{ name: 'influence_discovery' }"
                >Influencer Discovery</router-link
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </li>
      <li>
        <a-dropdown placement="bottomLeft">
          <a
            class="ant-dropdown-link"
            @click="(e) => e.preventDefault()"
            :class="isActMenu == 'Creatives' ? 'active' : ''"
          >
            Creatives
            <a-icon type="caret-down" class="c-down" />
          </a>
          <a-menu slot="overlay" class="menu-dropdown">
            <a-menu-item v-if="hasMediumList">
              <router-link :to="{ name: 'creative_banner' }"
                >Banners/Images</router-link
              >
            </a-menu-item>
            <a-menu-item v-if="hasMediumList">
              <router-link :to="{ name: 'creative_text' }"
                >Texts/Emails</router-link
              >
            </a-menu-item>
            <a-menu-item v-if="hasMediumSearch">
              <router-link :to="{ name: 'creative_coupon' }"
                >Coupons</router-link
              >
            </a-menu-item>
            <a-menu-item v-if="hasMediumSearch">
              <router-link :to="{ name: 'creative_datafeed' }"
                >Datafeed</router-link
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </li>
      <li>
        <a-dropdown placement="bottomLeft">
          <a
            class="ant-dropdown-link"
            @click="(e) => e.preventDefault()"
            :class="isActMenu == 'Tools' ? 'active' : ''"
          >
            Tools
            <a-icon type="caret-down" class="c-down" />
          </a>
          <a-menu slot="overlay" class="menu-dropdown">
            <a-menu-item>
              <router-link :to="{ name: 'tools_rules' }"
                >Commission Rules</router-link
              >
            </a-menu-item>
            <a-menu-item>
              <router-link :to="{ name: 'tools_api' }">API Docs</router-link>
            </a-menu-item>
            <a-menu-item>
              <router-link :to="{ name: 'RecruitmentPage' }"
                >Recruitment Page</router-link
              >
            </a-menu-item>
            <a-menu-item>
              <router-link :to="{ name: 'PpcRestrictionRules' }"
                >PPC Restriction Rules</router-link
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </li>
      <li id="header-menu-account">
        <a-dropdown placement="bottomLeft">
          <a
            class="ant-dropdown-link"
            @click="(e) => e.preventDefault()"
            :class="isActMenu == 'My Account' ? 'active' : ''"
          >
            <span
              @click="
                $route.name != 'account-edit' &&
                  $router.push({ name: 'account-edit' })
              "
              >My Account</span
            >
            <a-icon type="caret-down" class="c-down" />
          </a>
          <a-menu slot="overlay" class="menu-dropdown">
            <!-- <a-menu-item> -->
            <!-- <router-link :to="{ name: 'details' }">Account Details</router-link>
            </a-menu-item>
            <a-menu-item>
              <router-link :to="{ name: 'communications' }">Communications</router-link>
            </a-menu-item>
            <a-menu-item>
              <router-link :to="{ name: 'management' }">Role Management</router-link>
            </a-menu-item> -->
            <a-menu-item>
              <router-link :to="{ name: 'balance_details' }"
                >Balance Details</router-link
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </li>
    </ul>
  </div>
</template>

<script>
import inspectUserPermissions from "@/utils/permissionsJudge";
export default {
  name: "Menu",
  data() {
    return {
      routeChange: "",
      // 是否有Publisher List菜单的权限
      hasMediumList: true,
      // 是否有Publisher search菜单的权限
      hasMediumSearch: true,
      // 是否有Transaction List菜单的权限
      hasTransactionList: true,
      // 是否有Report Call菜单的权限
      hasReportCall: true,
    };
  },
  computed: {
    // 权限判断
    // authorityJudgment() {
    //   return this.$store.state.authorityJudgment;
    // },
    isActMenu() {
      // console.log(this.$store.state.isActMenu);
      return this.$store.state.isActMenu;
    },
  },
  created() {},
  mounted() {
    let authorityJudgment = this.$store.state.authorityJudgment;
    this.hasMediumList = inspectUserPermissions(
      "medium/list",
      authorityJudgment
    );
    this.hasMediumSearch = inspectUserPermissions(
      "medium/search",
      authorityJudgment
    );
    this.hasTransactionList = inspectUserPermissions(
      "transaction/list",
      authorityJudgment
    );
    this.hasReportCall = inspectUserPermissions(
      "report/call",
      authorityJudgment
    );
  },
};
</script>

<style lang="less" scoped>
.menu-wrap {
  background-color: #fff;
  height: 58px;
  width: 100%;
  border-bottom: 1px solid #eee;

  .menu {
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    padding-left: 0px;

    li {
      list-style-type: none;
      margin-right: 30px;
      font-family: "dtc-bold";
      color: #333;
      font-size: 1.1429rem;
      cursor: pointer;

      a {
        color: #333333;
        line-height: 23px;

        .c-down {
          opacity: 0;
          color: #2898ff;
        }

        &:hover {
          color: #2898ff;

          .c-down {
            opacity: 1;
          }
        }
      }
    }
  }

  .active {
    color: #2898ff !important;
    position: relative;

    &::before {
      content: "";
      width: calc(100% - 22px);
      // margin-left: 20px;
      height: 2px;
      position: absolute;
      background: #2898ff;
      bottom: -19px;
    }
  }
}

.menu-dropdown {
  box-sizing: border-box;
  width: 180px;
  padding: 5px 10px;
  margin-top: 6px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  margin-left: -20px;

  /deep/ .ant-dropdown-menu-item,
  /deep/ .ant-dropdown-menu-submenu-title {
    transition: unset;
    text-align: left;

    a {
      transition: unset;
      color: #333;

      &:hover {
        color: #2898ff;
      }
    }

    &:hover {
      background: unset;
    }
  }

  // /deep/ .ant-dropdown-menu-submenu-title {
  //   transition: unset;
  //   a {
  //     transition: unset;
  //     color: #2898ff;
  //   }
  //   &:hover {
  //     font-family: "dtc-bold";;
  //     background: unset;
  //   }
  // }
}
</style>
