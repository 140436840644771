<template>
  <div :class="'guide guide-' + guide">
    <template v-if="guide == 1">
      <div class="guide-block" :style="customStyle">
        <div class="guide-tips">
          <img
            src="@/assets/close.svg"
            alt=""
            @click="hideGuide"
            class="guide-close"
          />
          <div class="guide-content">
            Please go to “My Account” to finish your account setup.
          </div>
          <div class="guide-step">
            <span>{{ guide }}</span>
            <span>/4</span>
          </div>
          <div class="guide-next" @click="goNext">Next</div>
        </div>
      </div>
    </template>
    <template v-else-if="guide == 2">
      <div class="guide-block" :style="customStyle">
        <div class="guide-tips">
          <img
            src="@/assets/close.svg"
            alt=""
            @click="hideGuide"
            class="guide-close"
          />
          <div class="guide-content">Upload your company logo here</div>
          <div class="guide-step">
            <span>{{ guide }}</span>
            <span>/4</span>
          </div>
          <div class="guide-next" @click="goNext">Next</div>
        </div>
      </div>
    </template>
    <template v-else-if="guide == 3">
      <div class="guide-block" :style="customStyle">
        <div class="guide-tips">
          <img
            src="@/assets/close.svg"
            alt=""
            @click="hideGuide"
            class="guide-close"
          />
          <div class="guide-content">
            Filling your programs details so our publishers can better
            understand your program!
          </div>
          <div class="guide-step">
            <span>{{ guide }}</span>
            <span>/4</span>
          </div>
          <div class="guide-next" @click="goNext">Next</div>
        </div>
      </div>
    </template>
    <template v-else-if="guide == 4">
      <div class="guide-block" :style="customStyle">
        <div class="guide-program-setting-menu">
          <div>
            <img
              class="menu-icon"
              src="@/assets/account/program_settings_checked.png"
              alt=""
            />
            Program Settings
          </div>
        </div>
        <div class="guide-tips">
          <img
            src="@/assets/close.svg"
            alt=""
            @click="hideGuide"
            class="guide-close"
          />
          <div class="guide-content">
            <p>Setup your program setting here</p>
            <p style="color:#FCBF53FF;font-size: .8571rem;line-height: 18px;">
              Note: You cannot activate your program until you upgraded to a
              paid plan
            </p>
          </div>
          <div class="guide-step">
            <span>{{ guide }}</span>
            <span>/4</span>
          </div>
          <div class="guide-next" @click="goNext">Done</div>
        </div>
      </div>
    </template>
    <template v-else-if="guide == -1">
      <div class="show-guide-floating">
        <img
          src="@/assets/guide-floating-close.svg"
          alt=""
          @click="closeGuide"
        />
        <img src="@/assets/guide-floating.svg" alt="" @click="goNext" />
      </div>
    </template>
  </div>
</template>

<script>
// http://localhost:8080/brand/signup?mail=e784icYG_aCFzseI7htWt_bGnUwv15it6p3vosSe9X1tJfonLrbGfxEptCJJPQSw_c_c
export default {
  data() {
    return {
      customStyle: {},
    };
  },
  watch: {
    "$store.state.guide": {
      handler: function(newVal, oldVal) {
        this.$nextTick(() => {
          this.showGuide();
        });
      },
    },
  },
  computed: {
    guide() {
      return this.$store.state.guide;
    },
  },
  mounted() {
    this.showGuide();
  },
  methods: {
    closeGuide() {
      this.$store.commit("setGuide", 0);
    },
    hideGuide() {
      if (this.guide == 4) {
        this.$store.commit("setGuide", 0);
      } else {
        this.$store.commit("setGuide", -1);
      }
    },
    goNext() {
      if (this.guide < 4) {
        if (this.guide == -1) {
          if (this.$route.name == "account-edit") {
            this.$store.commit("setGuide", 1);
          } else {
            this.$router
              .push({
                name: "account-edit",
              })
              .then(() => {
                this.$store.commit("setGuide", 1);
              });
          }
        } else if (this.guide == 3) {
          this.$router
            .push({
              name: "program-settings",
            })
            .then(() => {
              this.$store.commit("setGuide", this.guide + 1);
            });
        } else {
          this.$store.commit("setGuide", this.guide + 1);
        }
      } else {
        this.$store.commit("setGuide", 0);
      }
    },
    showGuide() {
      if (this.guide == 1) {
        this.showNormalGuide(document.getElementById("header-menu-account"));
      } else if (this.guide == 2) {
        this.showNormalGuide(
          document.getElementsByClassName("account-avatar")[0]
        );
      } else if (this.guide == 3) {
        this.showNormalGuide(document.getElementById("account-detail-guide-3"));
      } else if (this.guide == 4) {
        this.$nextTick(() => {
          this.showNormalGuide(
            document.getElementsByClassName("child-details")[0]
          );
        });
      } else {
        this.customStyle = null;
      }
    },
    showNormalGuide(ele) {
      let offsetWidth = ele.offsetWidth;
      let offsetHeight = ele.offsetHeight;
      let offsetLeft = ele.offsetLeft;
      let offsetTop = ele.offsetTop;
      let scrollWidth = document.body.scrollWidth;
      let scrollHeight = document.body.scrollHeight;

      let customStyle = {};
      customStyle.width = scrollWidth + "px";
      customStyle.height = scrollHeight + "px";

      let padding = 12;
      if (this.guide == 3 || this.guide == 4) {
        padding = 20;
      }

      customStyle.borderLeftWidth = offsetLeft - padding + "px";
      if (ele.id == "header-menu-account") {
        customStyle.borderRightWidth =
          scrollWidth - offsetWidth - offsetLeft - padding + 20 + "px";
      } else {
        customStyle.borderRightWidth =
          scrollWidth - offsetWidth - offsetLeft - padding + "px";
      }
      customStyle.borderTopWidth = offsetTop - padding + "px";
      customStyle.borderBottomWidth =
        scrollHeight - offsetHeight - offsetTop - padding + "px";
      this.customStyle = customStyle;
    },
  },
};
</script>

<style lang="less" scoped>
.guide {
  .guide-block {
    position: absolute;
    left: 0;
    top: 0;
    border-color: rgba(0, 0, 0, 0.4);
    border-style: solid;
    background: url(~@/assets/guide-radius-top-left.svg) no-repeat top left,
      url(~@/assets/guide-radius-top-right.svg) no-repeat top right,
      url(~@/assets/guide-radius-bottom-left.svg) no-repeat bottom left,
      url(~@/assets/guide-radius-bottom-right.svg) no-repeat bottom right;
    background-size: 10px;
  }

  &.guide-1 {
    .guide-tips {
      width: 357px;
      height: 143px;
      top: 70px;
      left: 0;
    }
  }

  &.guide-2 {
    .guide-tips {
      width: 308px;
      height: 127px;
      left: 290px;
      top: 0;

      &::before {
        top: 20px;
        left: -20px;
        border-top: 10px solid transparent;
        border-right: 10px solid #ffffff;
        border-bottom: 10px solid transparent;
        border-left: 10px solid transparent;
      }
    }
  }

  &.guide-3 {
    .guide-tips {
      width: 305px;
      height: 166px;
      top: 100px;
      left: -330px;

      &::before {
        top: 2.8571rem;
        left: unset;
        right: -20px;
        border-top: 10px solid transparent;
        border-left: 10px solid #ffffff;
        border-bottom: 10px solid transparent;
        border-right: 10px solid transparent;
      }
    }
  }

  &.guide-4 {
    .guide-program-setting-menu {
      position: absolute;
      left: -235px;
      width: 235px;
      top: 81px;
      height: 88px;
      background: #fff;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 27px;
      color: #2898ff;
      border-radius: 12px 0 0 12px;

      div {
        width: 183px;
        height: 44px;
        background: #f2f9ff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
      }
    }

    .guide-tips {
      width: 305px;
      height: 166px;
      top: 260px;
      left: -330px;

      &::before {
        top: 2.8571rem;
        left: unset;
        right: -20px;
        border-top: 10px solid transparent;
        border-left: 10px solid #ffffff;
        border-bottom: 10px solid transparent;
        border-right: 10px solid transparent;
      }
    }
  }

  .guide-tips {
    background: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.19);
    border-radius: 12px;
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
    padding: 18px;

    &::before {
      // 三角
      content: "";
      position: absolute;
      top: -20px;
      left: 20px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #ffffff;
      border-left: 10px solid transparent;
    }

    .guide-close {
      position: absolute;
      right: 12px;
      top: 12px;
      height: 12px;
      cursor: pointer;
    }

    .guide-content {
      width: 100%;
      font-size: 1.1429rem;
      color: #333333;
      line-height: 23px;
      text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.19);
      padding-top: 12px;
      padding-bottom: 3.4286rem;
    }

    .guide-step {
      position: absolute;
      bottom: 18px;
      left: 18px;
      font-size: 1rem;

      span {
        &:first-child {
          color: #333;
        }

        &:last-child {
          color: #999;
        }
      }
    }

    .guide-next {
      width: 82px;
      height: 36px;
      background: #2898ff;
      box-shadow: 0px 0px 12px 0px #9fd0ffbf;
      border-radius: 4px;
      position: absolute;
      bottom: 18px;
      right: 18px;
      line-height: 36px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: #52b1ff;
      }
    }
  }
}

.menu-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.show-guide-floating {
  height: 56px;
  width: 56px;
  cursor: pointer;
  user-select: none;
  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translateX(520px);
  z-index: 6;

  img:first-child {
    border-radius: 99px;
    position: absolute;
    top: 2px;
    right: 2px;
    display: none;
  }

  &:hover {
    img:first-child {
      display: block;
    }
  }

  img:last-child {
    height: 56px;
    width: 56px;
    border-radius: 99px;
    box-shadow: 0px 0px 15px 0px #9fd0ffbf;
  }
}
</style>
