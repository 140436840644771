<template>
  <div>
    <Loading v-if="isLoading"></Loading>
    <chat-modal :medium-id="chatMediumId" :user-id="chatUserId" @close="closeChatPop"></chat-modal>
    <div class="home-wrap">
      <nav-header></nav-header>
      <Menu v-if="$route.name !== 'tools_Recruitment'"></Menu>
      <div class="content">
        <router-view v-if="!isLoading"></router-view>
      </div>
    </div>
    <div class="clearboth"></div>
    <Guide v-if="!isLoading" />
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import axios from "@/utils/request";
import NavHeader from "../components/NavHeader.vue";
import NavFooter from "../components/NavFooter.vue";
import Menu from "./../components/Menu.vue";
import ChatModal from "../components/ChatModal.vue";
import Guide from "../components/Guide.vue";

export default {
  name: "Home",
  components: {
    Guide,
    ChatModal,
    NavHeader,
    NavFooter,
    Menu,
  },
  data() {
    return {
      loading: 2,
      chatMediumId: null,
      chatUserId: null,
    };
  },
  computed: {
    isLoading() {
      return this.loading > 0;
    },
  },
  methods: {
    closeChatPop() {
      this.chatMediumId = null;
      this.chatUserId = null;
    },
  },
  watch: {
    "$store.state.chatMediumId": {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.chatMediumId = newVal;
          this.$store.commit("setChatMediumId", null);
        }
      },
    },
    "$store.state.chatUserId": {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.chatUserId = newVal;
          this.$store.commit("setChatUserId", null);
        }
      },
    },
  },
  created() {
    this.refreshUserInfo().then(() => {
      this.$nextTick(() => {
        this.loading--;
      });
    });

    axios.get("/api_admin.php?a=common/config").then((res) => {
      if (res.code === 0) {
        this.$store.commit("commonConfig", res.data);
        this.$nextTick(() => {
          this.loading--;
        });
      } else {
        this.$message.warning("Service is busy. please refresh page");
      }
    });
  }
};
</script>

<style lang="less" scoped>
.home-wrap {
  .content {
    width: 1200px;
    margin: auto;
    min-height: calc(100vh - 60px - 60px - 78px);
  }
}

/deep/ .footer-pagination {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 32px;
}
</style>
